import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/omnes/OmnesSemiCond-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/omnes/OmnesSemiCond-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../fonts/omnes/OmnesSemiCond-Medium.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/omnes/OmnesSemiCond-Bold.woff2\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--font-family-sans\"}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/happitu-website/happitu-website/src/app/context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/home/runner/work/happitu-website/happitu-website/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/happitu-website/happitu-website/src/components/visual-editing.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/src/styles/global.css");
